import React, {useRef} from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import CertiComp from "../components/certi/certiComp";


export default function Login() {
    const temp = useRef(null)
    return (
        <div>
            <Header registRef={temp} infoRef={temp} limitRef={temp} qaRef={temp} type={'03'}/>
            <div className='flex_center h-[calc(100vh-20vh)]'>
                <CertiComp />
            </div>
            <div className='fixed bottom-0 w-full'>
                <Footer/>
            </div>
        </div>
    )

}
