import {SubmitHandler, useForm} from "react-hook-form";
import React, {SetStateAction, useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";
import cn from 'classnames';
import axiosInstance from "../../api/axiosInstans";
import {Agree} from "./agree";
import {getErrorMsg, googleSheet, makeCode, sendMsg, updateSet} from "../../utils/common";
import {useSearchParams} from "react-router-dom";
import {bankList, carList} from "../../utils/dataSet";
const rows = [
    {
        item: '개인(신용)정보의 수집, 이용에 관한 사항 (필수)',
        id : 'collectionYn',
        name: '[보기]',
    },
    {
        item: '개인(신용)정보의 조회에 관한 사항 (필수)',
        id : 'provisionYn',
        name: '[보기]',
    },
    {
        item: '개인(신용)정보의 제공에 관한 사항 (필수)',
        id : 'inquiryYn',
        name: '[보기]',
    },
    {
        item: '민감정보 및 고유식별정보의 처리에 관한 사항 (필수)',
        id : 'sharingYn',
        name: '[보기]',
    },
    {
        item: '마켓팅 활용 동의에 관한 사항',
        id : 'marketingYn',
        name: '[보기]',
    }
];

interface Param {
    cName ?: string;
    cCell : string;
    cJumin : string;
    carType ?: string;
    businessType ?: string;
    carNum ?: string;
    carUse ?: string;
    payType ?: string;
    payNum ?: string;
    payExpir ?: string;
    payDate ?: string;
    obuYn ? : string;
    file : any;
    collectionYn ?: boolean;
    provisionYn ?: boolean;
    inquiryYn ?: boolean;
    marketingYn ?: string;
    sharingYn ?: boolean;
}
interface UserInfo {
    cName : string;
    cCell : string;
}

interface Props {
    user : UserInfo | null
    setStep : React.Dispatch<SetStateAction<string>>;
    setCmpk : React.Dispatch<SetStateAction<string>>;

}
export default function RegisterForm({user, setStep, setCmpk } : Props) {
    const isMobile = useMediaQuery({maxWidth : 930})
    const [searchParams] = useSearchParams();
    const key = searchParams.get('key');
    const [agreeOpen, setAgreeOpen] = useState(false);
    const [type, setType] = useState('');
    const [checkedIdsSet, setCheckedIdsSet] = useState(new Set());
    const numChecked = checkedIdsSet.size;
    const isMobi = /Mobi/i.test(window.navigator.userAgent);
    const [sendFlag, setSendFlag] = useState(false);
    const [code, setCode] = useState('');
    const [userCode, setUserCode] = useState('');
    const [cJumin, setCjumin] = useState('');

    const {
        register,
        watch,
        setValue,
        handleSubmit,
    } = useForm<Param>({
        defaultValues: {
            cName : user?.cName,
            cCell : user?.cCell,
            cJumin : '',
            obuYn : '',
            carNum : '',
            businessType :'',
            carType : '',
            carUse : '',
            payType : '',
            payNum : '',
            payExpir : '',
            payDate : '',
            collectionYn : false,
            provisionYn : false,
            inquiryYn : false,
            marketingYn : 'N',
            sharingYn : false,
            file : ''
        },
        mode: 'onChange',
    });



    const handleOnChange = (id : any, item : string) => {
        setCheckedIdsSet((prevSet) => updateSet(prevSet, id, item, setValue));
    };

    // @ts-ignore
    const toggleAllCheckedById = ({ target: { checked } }) => {
        if (checked) {
            const allChecked = new Set(rows.map(({ id }) => id));
            setCheckedIdsSet(allChecked);
        } else {
            setCheckedIdsSet(new Set());
        }
    };


    const onSubmit : SubmitHandler<Param> = async (data : Param) => {
        try{
            if(checkedIdsSet.has('collectionYn') && checkedIdsSet.has('provisionYn') && checkedIdsSet.has("inquiryYn") && checkedIdsSet.has("sharingYn")){
                if(code && (code === userCode)){
                    let formData = new FormData();

                    for(let key in data){
                        if(key !== 'file' && key !== 'cJumin' && key !== 'marketingYn'){
                            // @ts-ignore
                            formData.append(key, data[key]);
                        }
                    }
                    if(key){
                        formData.append('cmpk', key);
                    }else {
                        formData.append('cmpk', 'no');
                    }
                    formData.append('cJumin', cJumin);
                    formData.append('birth', cJumin.substring(0,6));
                    if(checkedIdsSet.has('marketingYn')){
                        formData.append('marketingYn', 'Y');
                    }else {
                        formData.append('marketingYn', 'N');
                    }
                    for(let image of data.file){
                        formData.append('photos',image);
                    }
                    const res = await axiosInstance.post('/api1001', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                    axiosInstance.post('/count', {job : 'submit'}).then(r => console.log('count'));
                    // @ts-ignore
                    if(res.code === '200'){
                        // @ts-ignore
                        setCmpk(res.cmpk);
                        let googleResult = await googleSheet(formData);
                        if(googleResult === 200){
                            setStep('step2');
                        }
                    }else {
                        // @ts-ignore
                        alert(res.msg);
                    }
                }else {
                    alert("인증번호가 틀립니다.");
                }
            }else {
                alert("정보제공 동의는 필수 입니다.")
            }

        }catch(e){
            alert('서비스 오류입니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
        }
    }

    const onError = (errors : any) => {
        console.log(errors);
        let errMsg = getErrorMsg(Object.keys(errors)[0]);
        alert(`${errMsg}`);
    }

    const onClickHandler =  async (e : React.MouseEvent<HTMLButtonElement>) => {
        try {
            e.preventDefault();
            let temp = makeCode();
            setCode(temp);
            console.log(temp);
            await sendMsg(cell, temp).then(r => {
                setSendFlag(false);
            });


        }catch (e){
            alert('서비스 오류입니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
        }
    }
    const car = watch("carUse");
    const bnsType = watch("businessType");
    const payType = watch("payType");
    const cell = watch('cCell');
    const jumin = watch('cJumin');





    useEffect(() => {
        if(code !== ''){
            setTimeout(function(){
                setSendFlag(true);
            }, 10000);
        }
    }, [sendFlag]);


    useEffect(() => {

        if(jumin.length === 13){
            setCjumin(jumin);
            setValue("cJumin", jumin.replace(/-/g, '').replace(/(\d{6})(\d{1})(\d{6})/, '$1-$2******'));
        }
    }, [jumin]);
    useEffect(() => {
        if(cell !== undefined){
            setValue("cCell",cell.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/-{1,2}$/g, ''));
            if(cell.replace(/-/g, '').length >= 11){
                setSendFlag(true);
            }else {
                setSendFlag(false)
            }
        }
    }, [cell]);

    useEffect(() => {
        if(user) {
            setValue("cCell", user.cCell);
            setValue('cName', user.cName);
        }
    }, [user]);

    useEffect(() => {
        if (agreeOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [agreeOpen]);
    return (
        <div className={cn('w-full flex_center flex-col z-10 pt-[100px] overflow-y-auto h-full', {'text-[12px] overflow-y-auto h-full': isMobi})}>
            <form id={'regi'}
                  className={cn('px-8 my-8 space-y-4 base_font relative', {'w-full': isMobi, 'w-[600px]': !isMobi})}
                  onSubmit={handleSubmit(onSubmit, onError)}
            >
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        이름
                    </label>
                    <input
                        type={'text'}
                        defaultValue={user?.cName || ''}
                        className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                        {...register('cName', {
                            required: true
                        })}
                    />
                </div>
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        주민등록번호
                    </label>
                    <input type={'text'}
                           className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                           {...register('cJumin', {
                               pattern : /^\S+$/,
                               required: true
                           })}
                           placeholder={'-없이 입력해주세요'}
                           maxLength={14}
                    />
                </div>
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        휴대폰 번호
                    </label>
                    <input type={'text'}
                           maxLength={13}
                           defaultValue={user?.cCell || ''}
                           placeholder={'-없이 입력해주세요'}
                           className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                           {...register('cCell', {
                               required: true
                           })}
                    />
                </div>
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        인증번호
                    </label>
                    <div className={cn('basis-2/3 flex', {'mobi_input2': isMobile, 'input2': !isMobile})}>
                        <input type={'text'} className='h-full rounded-l-lg input_hover pl-2 w-full'
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserCode(e.currentTarget.value)}/>
                        <button type='button' className={cn('min-w-[60px] text-white  rounded-r-lg', {
                            'bg-gray-400 text-white rounded-r-lg': !sendFlag,
                            'bg_base': sendFlag
                        })} disabled={!sendFlag} onClick={onClickHandler}>전송
                        </button>
                    </div>
                </div>
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        개인 / 법인
                    </label>
                    <select className={cn('basis-2/3', {'mobi_select': isMobile, 'select': !isMobile})}
                            {...register('businessType', {
                                required: true
                            })}
                    >
                        <option value={''} disabled={true}>선택</option>
                        <option value={'01'}>개인</option>
                        <option value={'02'}>법인</option>
                    </select>
                </div>
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        차량 용도
                    </label>
                    <div className='flex flex-col basis-2/3'>
                        <select className={cn('w-full', {'mobi_select': isMobile, 'select': !isMobile})}
                            //onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setType(e.currentTarget.value)}
                                {...register('carUse', {
                                    required: true,
                                })}
                        >
                            <option value={''} disabled={true}>선택</option>
                            <option value={'01'}>택시</option>
                            <option value={'02'}>화물</option>
                        </select>
                    </div>
                </div>
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        차종
                    </label>
                    {
                        car === '' ? <p>차량용도를 먼저 선택해주세요.</p>
                            :
                        <select className={cn('basis-2/3', {'mobi_select': isMobile, 'select': !isMobile})}
                                {...register('carType', {
                                    required: true
                                })}
                        >
                            <option value={''} disabled={true}>
                                선택
                            </option>
                            {
                                carList(car)?.map((state, idx) =>
                                <option key={idx} value={state}>
                                    {state}
                                </option>
                                )
                            }
                        </select>
                    }
                </div>
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        차량 번호
                    </label>
                    <input type={'text'}
                           className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                           {...register('carNum', {
                               required: true
                           })}
                    />
                </div>
                {/*<div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        OBD(단말기)
                    </label>
                    <select className={cn('basis-2/3', {'mobi_select': isMobile, 'select': !isMobile})}
                            {...register('obuYn', {
                                required: true
                            })}>
                        <option value={''} disabled={true}>선택</option>
                        <option value={'Y'}>설치</option>
                        <option value={'N'}>미설치</option>
                    </select>
                </div>*/}
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        결제 수단
                    </label>
                    <select className={cn('basis-2/3', {'mobi_select': isMobile, 'select': !isMobile})}
                            {...register('payType', {
                                required: true
                            })}>
                        <option value={''} disabled={true}>선택</option>
                        <option value={'01'}>계좌이체</option>
                        <option value={'02'}>카드</option>
                    </select>
                </div>
                {
                    payType === '01' ?
                        <>
                            <div className='w-full flex'>
                                <label className='basis-1/3 flex_center'>
                                    은행명
                                </label>
                                <select
                                    className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                                    {...register('payExpir', {
                                        required: true
                                    })}>
                                    {bankList().map((state, key) =>
                                        <option key={key}>
                                            {state}
                                        </option>
                                    )}
                                </select>
                            </div>
                            <div className='w-full flex'>
                                <label className='basis-1/3 flex_center'>
                                    계좌번호
                                </label>
                                <input type={'text'}
                                       className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                                       {...register('payNum', {
                                           required: true
                                       })}
                                />
                            </div>
                        </>
                        :
                        <>
                            <div className='w-full flex'>
                                <label className='basis-1/3 flex_center'>
                                    카드번호
                                </label>
                                <input type={'text'}
                                       className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                                       {...register('payNum', {
                                           required: true
                                       })}
                                />
                            </div>
                            <div className='w-full flex'>
                                <label className='basis-1/3 flex_center'>
                                    유효기간
                                </label>
                                <input type={'text'}
                                       className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                                       {...register('payExpir', {
                                           required: true
                                       })}
                                />
                            </div>
                        </>
                }
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        결제일
                    </label>
                    <select className={cn('basis-2/3', {'mobi_select': isMobile, 'select': !isMobile})}
                            {...register('payDate', {
                                required: true
                            })}>
                        <option value={'00'}>선택</option>
                        <option value={'매월 5일'}>5일</option>
                        <option value={'매월 10일'}>10일</option>
                        <option value={'매월 15일'}>15일</option>
                        <option value={'매월 20일'}>20일</option>
                        <option value={'매월 25일'}>25일</option>
                        <option value={'매월 30일'}>30일</option>
                    </select>
                </div>


                {
                    bnsType === '02' &&
                    <div className='w-full flex'>
                        <label className='basis-1/3 flex_center'>
                            가입 서류
                        </label>
                        <input type={'file'}
                               multiple={true}
                               className={cn('basis-2/3', {'mobi_file_input': isMobile, 'file_input': !isMobile})}
                               {...register('file', {
                                   required: false,
                               })}
                        />
                    </div>
                }
            </form>

            <div className={cn('bg-[#eeeeee] w-full ')}>
                <div className={cn('flex flex-col base_font p-4 mt-2 w-full space-y-1', {
                    'text-[10px]': isMobile,
                    'text-[14px]': !isMobile
                })}>
                    <table className='w-full'>
                        <thead>
                        <tr>
                            <th className='text-left'>
                                <input
                                    className='mr-2'
                                    type='checkbox'
                                    onChange={toggleAllCheckedById}
                                    checked={numChecked === rows.length}
                                />
                            </th>
                            <th className='text-left'>
                                아래 정보제공 동의에 대하여 모두 동의합니다.
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {rows.map(({id, name, item}) => (
                            <tr key={id}>
                                <td>
                                    <input
                                        type='checkbox'
                                        checked={checkedIdsSet.has(id)}
                                        onChange={() => handleOnChange(id, item)}
                                    />
                                </td>
                                <td>{item}</td>
                                <td onClick={(e: React.MouseEvent) => {
                                    setAgreeOpen(true);
                                    setType(id);
                                }}>{name}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className='w-full flex_center my-4'>
                    <button type={'submit'} form={'regi'}
                            className='border w-[120px] h-[40px] rounded-xl bg_base text-white font-bold'>접수
                    </button>
                </div>
            </div>
            {
                agreeOpen && <Agree type={type} agreeOpen={agreeOpen} setAgreeOpen={setAgreeOpen}/>
            }
        </div>
    )
}

