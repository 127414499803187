import React, {useState, useRef, useEffect} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
import {Buffer} from "buffer";
import dayjs from "dayjs";
import cn from 'classnames';
import axiosInstance from "../../api/axiosInstans";
import { useNavigate } from 'react-router-dom';
import {loadingActions} from "../../redux/slice/authSlice";
import {useDispatch} from "react-redux";

const CanvasWrapper = styled.div`
  position: relative;
  background-color : white;
  width: 350px;
  height: 200px;
`;

const CanvasPlaceholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
    background-color : white;
  transform: translate(-50%, -50%);
  color: black;
`;



interface Props{
    cmpk : string;
}

export default function  Sign({cmpk } : Props){
    const canvasRef = useRef<any>(null);
    const [isSigned, setIsSigned] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const save = async () => {
        try {
                const image = canvasRef.current.getTrimmedCanvas().toDataURL('image/png'); // image 변수에 이미지 다운로드 링크 할당, png를 jpg등의 다른 확장자로 변경 가능
                const decodedURL = image.replace(/^data:image\/\w+;base64,/, '');
                const buf = Buffer.from(decodedURL, 'base64');
                const blob = new Blob([buf], { type: 'image/png' });
                const sign = new FormData();
                let today = dayjs().format('YYYY-MM-DD');
                const file = new File([blob], cmpk+'_SIGN_' + today.toString().replaceAll('-', '') + '.png', { type: 'image/png' });

                sign.append('photos',file);
                sign.append('cmpk', cmpk);


                const response = await axiosInstance.post('/sign', sign, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // @ts-ignore
                if (response.code === '200') {
                    alert('가입 신청이 완료 되었습니다.');
                    navigate('/');
                } else {
                    alert('서비스 오류입니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
                }

        }catch(error) {
            console.log(error);
        }

    };

    useEffect(() => {
        axiosInstance.interceptors.request.use(function (config) {
            dispatch(loadingActions.GLOBAL_LOADING());
            return config;
        }, function(error){
            dispatch(loadingActions.GLOBAL_LOADED());
            return Promise.reject(error);
        })
        axiosInstance.interceptors.response.use((config) => {
            dispatch(loadingActions.GLOBAL_LOADED());
            return config;
        },(error) => {
            dispatch(loadingActions.GLOBAL_LOADED());
            return Promise.reject(error)
        })
    }, []);
    return (
        <div className='flex_center  my-16 flex-col pt-[100px]'>
            <CanvasWrapper className = 'border'>
                {!isSigned && (
                    <CanvasPlaceholder>
                        여기에 서명을 해주세요.
                    </CanvasPlaceholder>
                )}
                <SignatureCanvas
                    canvasProps={{width : '350px', height : '200px'}}
                    ref={canvasRef}
                    onBegin={() => {
                        setIsSigned(true);
                    }}
                />
            </CanvasWrapper>
            <div className='flex w-[350px] mt-4'>
                <button
                    type={'button'}
                    className='basis-1/2 mx-8 py-2 border bg_base text-white rounded-lg'
                    onClick={(event : React.MouseEvent<HTMLButtonElement>) => {
                        canvasRef.current.clear(); // 리셋
                        setIsSigned(false);
                    }}
                >
                    초기화
                </button>
                <button
                    type={'button'}
                    className={cn('basis-1/2 border mx-8 rounded-lg',{'bg-white': !isSigned, 'bg_base text-white' : isSigned})}
                    disabled={!isSigned} // 버튼 disabled
                    onClick={() => save()}
                >
                    확인
                </button>
            </div>
        </div>
    )}
