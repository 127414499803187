import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {authReducer, loadingReducer, userInfoReducer} from '../slice/authSlice';



const rootReducer = combineReducers({
    user: userInfoReducer,
    auth: authReducer,
    loading : loadingReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

