import {createSlice, PayloadAction} from "@reduxjs/toolkit";

// Define initial state for authSlice
interface AuthState {
    isLogin: boolean;
}

interface LoadingState {
    isLoading: boolean;
}

const initalLoadingState: LoadingState = {
    isLoading: false,
};

const initialAuthState: AuthState = {
    isLogin: false,
};

// Define initial state for userInfo
interface Users {
    cName: string;
    cCell: string;
    cJumin: string;
    carNum: string;
    carType: string;
    carUse: string;
    bussinessType: string;
}

const initialUserInfoState: Users[] = [];

// Create authSlice
const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        setAuth: (state, action: PayloadAction<AuthState>) => {
            state.isLogin = action.payload.isLogin;
        }
    }
});

// Create loading
const loadingSlice = createSlice({
    name: 'loading',
    initialState: initalLoadingState,
    reducers: {
        GLOBAL_LOADING : (state) => {
            state.isLoading = true;
        },
        GLOBAL_LOADED : (state) => {
            state.isLoading = false;
        }
    }
});

// Create userInfo slice
const userInfoSlice = createSlice({
    name: 'user',
    initialState: initialUserInfoState,
    reducers: {
        setUserInfo: (state, action: PayloadAction<Users>) => {
            state.push(action.payload);
        }
    }
});

// Extract actions and reducer for authSlice
export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
// Extract actions and reducer for userInfoSlice
export const userInfoActions = userInfoSlice.actions;
export const userInfoReducer = userInfoSlice.reducer;

export const loadingActions = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;
