import cn from "classnames";
import React, { SetStateAction, useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useMediaQuery} from "react-responsive";
import axiosInstance from "../../api/axiosInstans";
import {getClock, getErrorMsg, makeCode, sendMsg, updateSet} from "../../utils/common";
import 'react-datepicker/dist/react-datepicker.css';
import {Agree} from "./agree";
import { ko } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import styled from "styled-components";
import dayjs from "dayjs";


const CustomDate = styled.div`
    .react-datepicker{
        .react-datepicker__header {
            background-color: #fff;
            color: #fff;
            border-bottom: none;
            border-radius: 0;
        }
    }
    .react-datepicker-wrapper {
        width : 100%;
        padding : 0px 10px;
        height: 100%;
    }
    .react-datepicker__input-container{
        & > input {
            width : 100%;
            outline : none;
        }
        & > input:focus {
            outline : none;
        }
    }
`;
const rows = [
    {
        item: '개인(신용)정보의 수집, 이용에 관한 사항 (필수)',
        id : 'collectionYn',
        name: '[보기]',
    },
    {
        item: '개인(신용)정보의 조회에 관한 사항 (필수)',
        id : 'provisionYn',
        name: '[보기]',
    },
    {
        item: '개인(신용)정보의 제공에 관한 사항 (필수)',
        id : 'inquiryYn',
        name: '[보기]',
    },
    {
        item: '민감정보 및 고유식별정보의 처리에 관한 사항 (필수)',
        id : 'sharingYn',
        name: '[보기]',
    },
    {
        item: '마켓팅 활용 동의에 관한 사항',
        id : 'marketingYn',
        name: '[보기]',
    }
];
interface Param {
    cName : string;
    cCell : string;
    cJumin : string;
    date : string;
    time : string;
    collectionYn ?: boolean;
    provisionYn ?: boolean;
    inquiryYn ?: boolean;
    marketingYn ?: string;
    sharingYn ?: boolean;
}
interface Props {
    setIsOpen : React.Dispatch<SetStateAction<boolean>>;
}

export default function Consulting({setIsOpen} : Props){
    const isMobile = useMediaQuery({maxWidth : 930});
    const isHeight = useMediaQuery({minHeight : 896})
    const [agreeOpen, setAgreeOpen] = useState(false);
    const [type, setType] = useState('');
    const isMobi = /Mobi/i.test(window.navigator.userAgent);
    const [sendFlag, setSendFlag] = useState(false);
    const [code, setCode] = useState('');
    const [checkedIdsSet, setCheckedIdsSet] = useState(new Set());
    const numChecked = checkedIdsSet.size;
    const [userCode, setUserCode] = useState('');
    const {
        register,
        watch,
        setValue,
        handleSubmit,
    } = useForm<Param>({
        defaultValues: {
            cName : '',
            cCell : '',
            cJumin : '',
            date : dayjs().format('YYYY-MM-DD').toString(),
            time : '',
            collectionYn : false,
            provisionYn : false,
            inquiryYn : false,
            marketingYn : 'N',
            sharingYn : false,
        },
        mode: 'onChange',
    });
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const today = new Date();
    // 주말을 비활성화하는 함수
    const isWeekday = (date : Date) => {
        const day = date.getDay();
        // 일요일 (0)과 토요일 (6)을 제외한 나머지 날짜는 선택 가능
        return day !== 0 && day !== 6;
    };
    const cellPhone = watch('cCell').replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/-{1,2}$/g, '');
    const cell = watch('cCell');


    const handleOnChange = (id : any, item : string) => {
        setCheckedIdsSet((prevSet) => updateSet(prevSet, id, item, setValue));
    };

    // @ts-ignore
    const toggleAllCheckedById = ({ target: { checked } }) => {
        if (checked) {
            const allChecked = new Set(rows.map(({ id }) => id));
            setCheckedIdsSet(allChecked);
        } else {
            setCheckedIdsSet(new Set());
        }
    };

    const onSubmit : SubmitHandler<Param> = async (data : Param) => {
        try{
            if(checkedIdsSet.has('collectionYn') && checkedIdsSet.has('provisionYn') && checkedIdsSet.has("inquiryYn") && checkedIdsSet.has("sharingYn")){
                if(code && (code === userCode)){

                    data.marketingYn = checkedIdsSet.has('marketingYn') ? 'Y' : 'N';
                    const res = await axiosInstance.post('/reservation', data, {
                    });
                    // @ts-ignore
                    if(res.code === '200'){
                        axiosInstance.post('/count', {job : 'reservation'}).then(r => console.log('count'));
                        // @ts-ignore
                        alert('상담신청이 완료되었습니다. \n선택하신 날짜에 맞춰 연락드리겠습니다.');
                        setIsOpen(false);
                    }else {
                        // @ts-ignore
                        alert(res.msg);
                    }
                }else {
                    alert("인증번호가 틀립니다.");
                }
            }else {
                alert("정보제공 동의는 필수 입니다.");
            }
        }catch(e){
            alert('서비스 오류입니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
        }
    }

    const onClickHandler =  async (e : React.MouseEvent<HTMLButtonElement>) => {
        try {
            e.preventDefault();
            let temp = makeCode();
            setCode(temp);
            await sendMsg(cell, temp).then(r => {
                setSendFlag(false);
            });


        }catch (e){
            alert('서비스 오류입니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
        }
    }

    const onError = (errors : any) => {
        let errMsg = getErrorMsg(Object.keys(errors)[0]);
        alert(`${errMsg}`);
    }



    const dateSet = (date : any ) => {
        if(date !== null){
            setStartDate(date);
            setValue('date', dayjs(date).format('YYYY-MM-DD'));
        }
    }
    useEffect(() => {
        if (code !== '') {
            setTimeout(function () {
                setSendFlag(true);
            }, 10000);
        }
    },[sendFlag]);

    useEffect(() => {
        if(cell.length >= 12)  setSendFlag(true);
    }, [cell]);

    // @ts-ignore
    return(
        <div className={cn('w-full flex flex-col', {'text-[12px] overflow-y-scroll pt-[60px] h-full': isMobi})}>
            <form id={'regi'} className='w-[calc(100% - 20%)] px-8 my-8 space-y-4 base_font relative'
                  onSubmit={handleSubmit(onSubmit, onError)}
            >
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        이름
                    </label>
                    <input
                        type={'text'}
                        className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                        {...register('cName', {
                            required: true
                        })}
                    />
                </div>
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        상담 가능 일자
                    </label>
                    <CustomDate className={cn('basis-2/3 bg-white', {'mobi_input': isMobile, 'input': !isMobile})}>
                        <DatePicker selected={startDate}
                                    onChange={dateSet}
                                    filterDate={isWeekday}
                                    placeholderText="상담 날짜를 선택해주세요."
                                    locale={ko}
                                    minDate={today}
                                    dateFormat='yyyy-MM-dd'
                        />
                    </CustomDate>
                    {/*<input type={'date'}
                           {...register('date', {})}
                           min={today}
                           required
                           className={cn('basis-2/3 bg-white', {'mobi_input': isMobile, 'input': !isMobile})}
                    />*/}
                </div>
                <div className='w-full flex flex-col'>
                    <div className='w-full flex'>
                        <label className='basis-1/3 flex_center'>
                            상담 가능 시간
                        </label>
                        <select className={cn('basis-2/3 bg-white', {'input': !isMobile, 'mobi_input': isMobile})}
                                {...register('time', {
                                    required: true
                                })}>
                            {
                                getClock().map((state, index) =>
                                    <option key={index}>{state.hour}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className={cn('flex_center mt-2', {'text-[10px]': isMobile, 'text-[12px]': !isMobile})}>
                        <p>* 상담예약 시간은 30분단위로 가능합니다.</p>
                    </div>
                </div>

                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        휴대폰 번호
                    </label>
                    <input type={'text'}
                           value={cellPhone}
                           maxLength={13}
                           placeholder={'-없이 입력해주세요'}
                           className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                           {...register('cCell', {
                               required: true
                           })}
                    />
                </div>
                <div className='w-full flex'>
                    <label className='basis-1/3 flex_center'>
                        인증번호
                    </label>
                    <div className={cn('basis-2/3 flex', {'mobi_input2': isMobile, 'input2': !isMobile})}>
                        <input type={'text'} className='h-full rounded-l-lg input_hover pl-2'
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserCode(e.currentTarget.value)}/>
                        <button type='button' className={cn('text-white w-full rounded-r-lg', {
                            'bg-gray-400 text-white w-full rounded-r-lg': !sendFlag,
                            'bg_base': sendFlag
                        })} onClick={onClickHandler} disabled={!sendFlag}>전송
                        </button>
                    </div>
                </div>
            </form>
            <div className={cn('bg-white w-full ', {'absolute bottom-0': isHeight})}>
                <div className={cn('flex flex-col base_font p-4 mt-2 w-full space-y-1', {
                    'text-[10px]': isMobile,
                    'text-[14px]': !isMobile
                })}>
                    <table className='w-full'>
                        <thead>
                        <tr>
                            <th className='text-left'>
                                <input
                                    className='mr-2'
                                    type='checkbox'
                                    onChange={toggleAllCheckedById}
                                    checked={numChecked === rows.length}
                                />
                            </th>
                            <th className='text-left'>
                                아래 정보제공 동의에 대하여 모두 동의합니다.
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {rows.map(({id, name,item}) => (
                            <tr key={id}>
                                <td>
                                    <input
                                        type='checkbox'
                                        checked={checkedIdsSet.has(id)}
                                        onChange={() => handleOnChange(id, item)}
                                    />
                                </td>
                                <td>{item}</td>
                                <td onClick={(e: React.MouseEvent) => {
                                    setAgreeOpen(true);
                                    setType(id);
                                }}>{name}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className='w-full flex_center my-4'>
                    <button type={'submit'} form={'regi'}
                            className='border w-[120px] h-[40px] rounded-xl bg_base text-white font-bold'>접수
                    </button>
                </div>
            </div>
            {
                agreeOpen && <Agree type={type} agreeOpen={agreeOpen} setAgreeOpen={setAgreeOpen}/>
            }
        </div>
    )
}
