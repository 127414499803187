import React, { useRef} from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import {useParams} from "react-router-dom";
import Search from "../components/category/search";
import Request from "../components/category/request";
import Clime from "../components/category/clime";
import cn from "classnames";

export default function Main(){
    const {category} = useParams();
    const temp = useRef(null);
    //const num = '1670-0470';
    const isMobi = /Mobi/i.test(window.navigator.userAgent);
    /*const callCustCenter = (e : React.MouseEvent<HTMLButtonElement>) => {
        // eslint-disable-next-line no-restricted-globals
        location.href = "tel:" + num;
    }*/

    return (
        <div>
            {
                (category === 'search' || category === 'clime') ?
                <Header type={'02'} registRef={temp} infoRef={temp} limitRef={temp} qaRef={temp}/>
                    :
                <Header type={'03'} registRef={temp} infoRef={temp} limitRef={temp} qaRef={temp}/>
            }
            <div className={cn('flex_center ', {'pt-[140px] pb-[60px]' : isMobi, 'h-[calc(100vh-20vh)]' : !isMobi})}>
                {
                    category === 'search' && <Search/>
                }
                {
                    category === 'clime' && <Clime/>
                }
                {
                    category === 'request' && <Request/>
                }
            </div>
            <div className={cn(' w-full', {'fixed bottom-0' : !isMobi})}>
                <Footer/>
            </div>
        </div>
    )
}
