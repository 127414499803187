import cn from "classnames";
import {useEffect, useState} from "react";
import userDataSet, {getData} from "../../utils/common";
import { UserType} from "../../@types/common";



export default function Search(){
    const isMobi = /Mobi/i.test(window.navigator.userAgent);
    const [user, setUser] = useState<UserType | null>(null);
    useEffect(() => {
        getData().then(result => {
            setUser(result);
            console.log(result)
        }).catch(error => {
            console.error('Error fetching user info:', error);
            setUser(null);
        });

    }, []);
    return(
        <div className='flex-col w-full flex_center'>
            <div className={cn('flex_center flex-col border base_font ', {
                'w-[calc(100%-10%)] text-[12px]': isMobi,
                'w-[calc(100%-20%)] ': !isMobi
            })}>
                {
                    isMobi ?
                        <table className='w-full text-center'>
                            <tbody>
                            {
                                user &&
                                userDataSet().map((state, index) =>
                                    <tr key={index}>
                                        <td className='bg_base text-white py-2'>
                                            {state.title}
                                        </td>
                                        <td>
                                            {user[state.data]}
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                        :
                        <table className='w-full text-center'>
                            <thead className='bg_base text-white'>
                            <tr>
                                {userDataSet().map((state) =>
                                    <td key={state.key}>
                                        {state.title}
                                    </td>
                                )}
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                {
                                    user &&
                                    userDataSet().map((state, index) =>
                                            <td key={index}>{user[state.data]}</td>
                                    )
                                }
                            </tr>
                            </tbody>
                        </table>
                }
            </div>
                <a href={user?.location} target={'_blank'} rel="noreferrer"
                   className='my-8 w-[120px] h-[40px] bg_base flex_center text-white rounded-lg cursor-pointer'>계약서
                    다운로드
                </a>
        </div>

    )
}
