import React, {useState, useEffect, useRef} from 'react';
import cn from 'classnames';
import WhiteLogo from '../assets/images/PITIN LOGO_WHITE.png';
import PitInLogo from '../assets/images/pitin.png';
import {useMediaQuery} from "react-responsive";
import Menu from '../assets/images/menu.svg';
import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import {updateActiveSection} from "../utils/common";
interface Props {
    registRef: React.RefObject<HTMLDivElement>;
    infoRef: React.RefObject<HTMLDivElement>;
    limitRef: React.RefObject<HTMLDivElement>;
    qaRef: React.RefObject<HTMLDivElement>;
    type : string;
}



export default function Header({ type, registRef, infoRef, limitRef, qaRef }: Props) {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [activeSection, setActiveSection] = useState<string>('');
    const menuButton = useRef<HTMLDivElement>(null);
    const navRef = useRef<HTMLDivElement>(null);
    const {category}  = useParams();

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            setScrolled(isScrolled);
            updateActiveSection({qaRef, infoRef, limitRef, registRef, setActiveSection});
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [qaRef, infoRef, limitRef, registRef]);


    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const scrollToExample = () => {
        if (registRef.current) {
            scrollToSection(registRef);
            setIsOpen(false);
        }
    };

    const scrollToInfo = () => {
        if (infoRef.current) {
            scrollToSection(infoRef);
            setIsOpen(false);
        }
    };

    const scrollToLimit = () => {
        if (infoRef.current) {
            scrollToSection(limitRef);
            setIsOpen(false);
        }
    };

    const scrollToQa = () => {
        if (qaRef.current) {
            scrollToSection(qaRef);
            setIsOpen(false);
        }
    };

    return (
            <div className={cn('w-full fixed top-0 left-0 z-20', { 'border-b-[1px]': scrolled , 'bg_base' : isTabletOrMobile, 'bg-white' : isDesktopOrLaptop})}>
                        <div className='mx-auto my-0 h-[100px] flex items-center w-[calc(100%-80px)] max-w-[1280px]  relative'>
                            {
                                isDesktopOrLaptop ?
                                    <>
                                        <Link to={'/'}><img src={PitInLogo} alt={'topLogo'} width={150} /></Link>
                                        {
                                            type === '01' &&
                                                <div className='ml-auto'>
                                                    <ul className='flex space-x-8 cursor-pointer'>
                                                        <li className={cn('li_hover', {'font-bold': activeSection === 'register'})}
                                                            onClick={scrollToExample}>서비스 가입
                                                        </li>
                                                        <li className={cn('li_hover', {'font-bold': activeSection === 'limit'})}
                                                            onClick={scrollToLimit}>요금안내 및 보상한도
                                                        </li>
                                                        <li className={cn('li_hover', {'font-bold': activeSection === 'info'})}
                                                            onClick={scrollToInfo}>서비스 정보
                                                        </li>
                                                        <li className={cn('li_hover', {'font-bold': activeSection === 'qa'})}
                                                            onClick={scrollToQa}>Q & A
                                                        </li>
                                                        <li className={cn('li_hover')}>
                                                            <Link to={'main/search'} target={'_blank'}>마이페이지</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                        }
                                        {
                                            type === '02' &&
                                                <div className='ml-auto'>
                                                    <ul className='flex space-x-8 cursor-pointer'>
                                                        <li className={cn('li_hover', {'font-bold': category === "search"})}>
                                                            <Link to={'/main/search'}>가입조회</Link>
                                                        </li>
                                                        <li className={cn('li_hover', {'font-bold': category === 'clime'})}>
                                                            <Link to={'/main/clime'}>사고 접수 조회</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                        }
                                    </>
                                    :
                                    <>
                                        <Link to={'/'}><img src={WhiteLogo} alt={'topLogo'} width={150}/></Link>
                                        {
                                                (type === '01' || type === '02') &&
                                                    <div className='ml-auto relative'>
                                                        <div className='w-[45px] h-[40px] border bg_base rounded-md'
                                                             ref={menuButton}>
                                                            <button type={'button'} className='w-full h-full flex_center'
                                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => setIsOpen(!isOpen)}>
                                                                <img src={Menu} alt='menu' width={40}/>
                                                            </button>
                                                        </div>
                                                    </div>
                                            }
                                    </>
                            }
                        </div>
                        <div ref={navRef}
                             className={cn('nav_bar w-full text-black bg-white  flex_center flex-col text-[20px] transition-all duration-300 ease-in-out', {
                                 'h-[0px]': !isOpen,
                                 'h-[180px]': isOpen,
                             })}>
                            {
                                type === '01' &&
                                    <ul className={cn('flex flex-col flex_center cursor-pointer space-y-1', {'hidden': !isOpen})}>

                                        <li className={cn('li_hover', {'font-bold': activeSection === 'register'})}
                                            onClick={scrollToExample}>서비스 가입
                                        </li>
                                        <li className={cn('li_hover', {'font-bold': activeSection === 'limit'})}
                                            onClick={scrollToLimit}>요금안내 및 보상한도
                                        </li>
                                        <li className={cn('li_hover', {'font-bold': activeSection === 'info'})}
                                            onClick={scrollToInfo}>서비스 정보
                                        </li>
                                        <li className={cn('li_hover', {'font-bold': activeSection === 'qa'})}
                                            onClick={scrollToQa}>Q &
                                            A
                                        </li>
                                        <li className={cn('li_hover')}>
                                            <Link to={'main/search'} target={'_blank'}>마이페이지</Link>
                                        </li>
                                    </ul>
                            }
                            {
                                type === '02' &&
                                    <ul className={cn('flex flex-col flex_center cursor-pointer space-y-1', {'hidden': !isOpen})}>
                                        <li className={cn('li_hover', {'font-bold': category === "search"})}>
                                            <Link to={'/main/search'}>가입조회</Link>
                                        </li>
                                        <li className={cn('li_hover', {'font-bold': category === 'clime'})}>
                                            <Link to={'/main/clime'}>사고 접수 조회</Link>
                                        </li>
                                    </ul>
                            }
                        </div>
            </div>
    )
}
