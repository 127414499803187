import RegisterForm from "../components/modals/registerForm";
import React, {useEffect, useRef, useState} from "react";
import Header from "../components/header";
import {useSearchParams} from "react-router-dom";
import {getUserInfo} from "../utils/common";
import Sign from "../components/modals/Sign";

interface UserInfo {
    cName : string;
    cCell : string;
}
export default function Register(){
    const [step, setStep] = useState('step1');
    const [user, setUser] = useState<UserInfo | null>(null);
    const temp = useRef(null);
    const [searchParams] = useSearchParams();
    const [cmpk, setCmpk] = useState('');
    const key = searchParams.get('key');
    useEffect(() => {
        if (key !== null) {
            getUserInfo(key).then(result => {
                setUser(result);
            }).catch(error => {
                console.error('Error fetching user info:', error);
                setUser(null);
            });
        }
    }, []);
    return (
        <div>
            <Header type={'03'} registRef={temp} infoRef={temp} limitRef={temp} qaRef={temp}/>
            {
                step === 'step1' ?
                    <RegisterForm user={user} setStep={setStep} setCmpk={setCmpk}/>
                    :
                    <Sign cmpk={cmpk} />
            }
        </div>
    )
}
