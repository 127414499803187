import React from "react";
import {useSelector} from "react-redux";
import cn from "classnames";
import {useMediaQuery} from "react-responsive";

export default function Request(){
    const isMobile = useMediaQuery({maxWidth : 930})
    // @ts-ignore
    const userInfo = useSelector((state) => state.user);

    return(
        <div className='w-full pb-[120px] flex_center'>
            <div className='w-[calc(100%-20%)] px-8 my-8 space-y-4 base_font'>
                <div className='w-[calc(100%-20%)] flex'>
                    <label className='basis-1/3 flex_center'>
                        이름
                    </label>
                    <input
                        type={'text'}
                        className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                        value={userInfo[0] ? userInfo[0].cName : ''}
                    />
                </div>
                <div className='w-[calc(100%-20%)] flex'>
                    <label className='basis-1/3 flex_center'>
                        사업자
                    </label>
                    <input
                        type={'text'}
                        className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                        value={userInfo[0] ? userInfo[0].bussinessType : ''}
                    />
                </div>
                <div className='w-[calc(100%-20%)] flex'>
                    <label className='basis-1/3 flex_center'>
                        차량용도
                    </label>
                    <input
                        type={'text'}
                        className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                        value={userInfo[0] ? userInfo[0].carUse : ''}
                    />
                </div>
                <div className='w-[calc(100%-20%)] flex'>
                    <label className='basis-1/3 flex_center'>
                        차종
                    </label>
                    <input
                        type={'text'}
                        className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                        value={userInfo[0] ? userInfo[0].carType : ''}
                    />
                </div>
                <div className='w-[calc(100%-20%)] flex'>
                    <label className='basis-1/3 flex_center'>
                        차량번호
                    </label>
                    <input
                        type={'text'}
                        className={cn('basis-2/3', {'mobi_input': isMobile, 'input': !isMobile})}
                        value={userInfo[0] ? userInfo[0].carNum : ''}
                    />
                </div>
            </div>
        </div>
    )
}
