import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './assets/css/common.css';
import { Provider } from 'react-redux';
import { store} from "./redux/store";
import Loading from "./components/loading";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
            <Loading/>
            <App />
    </Provider>,
);
