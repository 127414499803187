import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Modal from "react-modal";
import {authRoutes, userRoutes} from "./routes/Routes";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import NonAuthLayout from "./components/NonAuthLayout";
import axios from "axios";
import {useDispatch} from "react-redux";
import {loadingActions} from "./redux/slice/authSlice";

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        axios.interceptors.request.use(function (config) {
            dispatch(loadingActions.GLOBAL_LOADING());
            return config;
        }, function(error){
            dispatch(loadingActions.GLOBAL_LOADED());
            return Promise.reject(error);
        })
        axios.interceptors.response.use((config) => {
            dispatch(loadingActions.GLOBAL_LOADED());
            return config;
        },(error) => {
            dispatch(loadingActions.GLOBAL_LOADED());
            return Promise.reject(error)
        })
    }, []);

    return (
        <div className="App font-[Pretendard] base_font">
            <BrowserRouter>
                <Routes>
                    {authRoutes.map((route, idx) => (
                        <Route path={route.path} element={<NonAuthLayout>{route.component}</NonAuthLayout>} key={idx} />
                    ))}

                    {userRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <Authmiddleware>
                                    {route.component}
                                </Authmiddleware>
                            }
                            key={idx}
                        />
                    ))}
                </Routes>
            </BrowserRouter>

        </div>
    );
}

Modal.setAppElement('#root')
export default App;
