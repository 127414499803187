import ProductWrap from "./productWrap";
import React, { SetStateAction} from 'react';
import QnaDiv from "./qnaDiv";
import PitIn from '../assets/images/pit-in.png';
import {useMediaQuery} from "react-responsive";
import cn from 'classnames';
import Icon1 from '../assets/images/01-01.png';
import Icon2 from '../assets/images/02-01.png';
import Icon3 from '../assets/images/03-01.png';
import Icon4 from '../assets/images/04-01.png';
import PitinIntro from '../assets/images/pitin-img.png';
interface Props {
    registerRef: React.RefObject<HTMLDivElement>;
    infoRef: React.RefObject<HTMLDivElement>;
    limitRef: React.RefObject<HTMLDivElement>;
    qaRef: React.RefObject<HTMLDivElement>;
    setIsOpen : React.Dispatch<SetStateAction<boolean>>
}
export default function Section({registerRef, infoRef, limitRef, qaRef, setIsOpen} : Props){
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isMobile = useMediaQuery({maxWidth : 930})

    const onClickHadnler = (e : React.MouseEvent<HTMLButtonElement>) => {
        const pdfUrl = 'https://pitin-ev.simg.kr/보험약관_신한 EV배터리 교체비용보상보험.pdf';
        // PDF 파일 다운로드 링크 생성
        const link = document.createElement('a');
         link.href = pdfUrl;
         link.setAttribute('download', '보험약관_신한 EV배터리 교체비용보상보험.pdf');
         link.setAttribute('target', '_blank');
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
    }
    return (
        <div className='w-full pb-[120px]'>
            <div
                className='mx-auto my-0 bg-white w-[calc(100%-80px)] max-w-[1062px] border-b-[2px] border-black  pb-[80px]' ref={registerRef}>
                <div className='flex flex-col text-black'>
                    <h2 className={cn('font-[400]', {
                        'text-[14px]': isTabletOrMobile,
                        'text-[18px]': isDesktopOrLaptop
                    })}>
                        영업용 전기차 배터리 관리 서비스란?
                    </h2>
                    <div className='flex'>
                        <div className={cn('mt-4 flex flex-col', {
                            'text-[16px]': isTabletOrMobile,
                            'basis-2/3 text-[24px]': isDesktopOrLaptop
                        })}>
                            <p>영업용 전기차량 운행시 발생할 수 있는 배터리의 파손시 <br/>
                                신품과 동일한 수준의<span className='font-bold'> 재제조 배터리로 교체해드립니다.</span>
                            </p>
                            <p className='mt-4'><span className='font-bold'>국내 최초</span> 전기차 배터리 팩 자차보험을 제공하는
                                <br/>
                                 <span className='font-bold'>혁신적인 서비스</span>입니다
                            </p>
                        </div>
                        {
                            !isMobile &&
                            <div className='basis-1/3 flex_center '>
                                <img src={PitIn} alt={'PitIn'}/>
                            </div>
                        }
                    </div>
                    <div className='flex space-x-6'>
                        <div className='mt-8 w-[192px] h-[40px] border rounded-2xl bg_base text-white'>
                            <button className='font-[700] w-full h-full'
                                    onClick={(e: React.MouseEvent) => setIsOpen(true)}>
                                상담 신청
                            </button>
                        </div>
                            <button onClick={onClickHadnler}
                                    className='mt-8 w-[192px] h-[40px] border rounded-2xl bg_base text-white font-[700]'>
                                보험약관 다운로드
                            </button>
                        {/* <div className='mt-8 w-[192px] h-[40px] border rounded-2xl bg_base text-white'>
                            <Link className='font-[700] w-full h-full flex_center
                                    to={'/main/request'} target={'_blank'}>
                                사고 접수
                            </Link>
                        </div>*/}
                    </div>
                </div>
            </div>
            <ProductWrap/>
            <div
                className='mx-auto my-0 bg-white w-[calc(100%-80px)] max-w-[1062px] border-b-[2px] border-black  pb-[120px]'
                ref={limitRef}>
                <div className='flex flex-col text-black'>
                    <h2 className={cn('font-bold', {
                        'text-[18px]': isTabletOrMobile,
                        'text-[24px]': isDesktopOrLaptop
                    })}>
                        서비스 비용
                    </h2>
                    <div className='w-full mt-4'>
                        <table
                            className={cn('w-full border rounded-lg base_font', {'text-[8px]': isTabletOrMobile})}>
                            <thead className='h-[60px] text-center bg_base text-white font-[700]'>
                            <tr className={cn('border-b pb-2', {'h-[40px]': isTabletOrMobile})}>
                                <th colSpan={2}>
                                    사업자 유형
                                </th>
                                <th>
                                    차종
                                </th>
                                <th>
                                    서비스 비용
                                </th>
                                <th>
                                    보장내용
                                </th>
                            </tr>
                            </thead>
                            <tbody className='bg-white'>
                            <tr className='border-b h-[60px]'>
                                <td className='text-center border-r min-w-[40px]' rowSpan={2}>
                                    택시
                                </td>
                                <td className='text-center border-r'>
                                    법인택시
                                </td>
                                <td className='text-center border-r border-l py-2' rowSpan={2}>
                                    니로(DE) EV <br/> <br/>
                                    니로(DE PVB)<br/> <br/>
                                    아이오닉EV<br/> <br/>
                                    EV 6
                                </td>
                                <td className='text-center '>
                                    75,000원
                                </td>
                                <td className='pl-4 border-l' rowSpan={4}>
                                    <div className='mb-2'>
                                        <p>▶ 사고, 침수, 충전중 화재, 자기 과실로 인한 배터리 파손 시 재제조 배터리로 교체</p>
                                        <p className={cn({
                                            'text-[7px]': isTabletOrMobile,
                                            'text-[12px]': !isTabletOrMobile
                                        })}>(※ 1 사고 당 1회 보장_동일 사고는 보장하지 않습니다)</p>
                                    </div>
                                    <div>
                                        <p className='font-bold'>[추가 혜택]</p>
                                        <p>▶ 기존 배터리 기능 저하로 인한 리퍼비시 배터리 구매 서비스</p>
                                        <p className={cn({
                                            'text-[7px]': isTabletOrMobile,
                                            'text-[12px]': !isTabletOrMobile
                                        })}>(※ 신품 배터리의 50% 할인된 금액으로 구매 가능)</p>
                                        <p>▶ 계약 3년 이상 유지 고객에게 리퍼비시 배터리 최대 65% 할인 구매 서비스 제공</p>
                                    </div>
                                </td>
                            </tr>
                            <tr className='border-b h-[60px]'>
                                <td className='text-center border-r'>
                                    개인(조합) 택시
                                </td>
                                <td className='text-center'>
                                    39,000원
                                </td>
                            </tr>
                            <tr className='border-b h-[60px]'>
                                <td className='text-center border-r min-w-[40px]' rowSpan={2}>
                                    물류
                                </td>
                                <td className='text-center border-r'>
                                    법인 물류
                                </td>
                                <td className='text-center border-r border-l' rowSpan={2}>
                                    봉고EV <br/><br/>
                                    포터EV
                                </td>
                                <td className='text-center' rowSpan={2}>
                                    35,000원
                                </td>
                            </tr>
                            <tr className='h-[60px]'>
                                <td className='text-center border-r'>
                                    개인 물류
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className={cn('ml-auto mt-2',{
                        'text-[7px]': isTabletOrMobile,
                        'text-[12px]': !isTabletOrMobile
                    })}>* VAT 별도</p>
                </div>
            </div>
            <ProductWrap/>
            <div
                className='mx-auto my-0 bg-white w-[calc(100%-80px)] max-w-[1062px] border-b-[2px] border-black  pb-[80px]' ref={infoRef}>
                <h2 className={cn('font-bold text-black', {
                    'text-[18px]': isTabletOrMobile,
                    'text-[24px]': isDesktopOrLaptop
                })}>
                    서비스 정보
                </h2>
                <div className='flex flex-col text-black space-y-28' ref={infoRef}>
                    <img src={PitinIntro} alt={'피트인 서비스 소개'}/>
                    {/*<div className='flex_center w-full'>
                        <div className={cn(' base_font', {
                            'text-[32px] basis-2/3': isDesktopOrLaptop,
                            'text-[22px] w-full': isTabletOrMobile
                        })}>
                            <div className='font-bold text-black flex flex-col'>
                                <p>
                                    영업용 EV 배터리팩 자차보험
                                </p>
                            </div>
                            <div className={cn({'text-[22px]': isDesktopOrLaptop, 'text-[18px]': isTabletOrMobile})}>
                                보증 기관에 상관없이 사고 및 자기과실로 인한 배터리 파손시 <br/>
                                신품과 동일한 수준의 <span className='font-bold'>재제조 배터리 교체 서비스</span>를 제공합니다.
                            </div>
                        </div>
                        {
                            isDesktopOrLaptop &&
                            <div className='basis-1/3 pt-[20px] pl-8'>
                                <img src={Icon1} alt={'아이콘1'} width={200}/>
                            </div>
                        }
                    </div>
                    <div className='w-full'>
                        <button onClick={onClickHadnler}
                                className='w-[192px] h-[40px] border rounded-2xl bg_base text-white font-[700]'>
                            보험약관 다운로드
                        </button>
                    </div>
                    <div className='flex_center w-full'>
                        <div className={cn('base_font', {
                            'text-[32px] basis-2/3': isDesktopOrLaptop,
                            'text-[22px] w-full': isTabletOrMobile
                        })}>
                            <div className='font-bold text-black flex flex-col'>
                                <p>
                                    차량 관리 / 차량 운행 관리
                                </p>
                            </div>
                            <div className={cn({'text-[22px]': isDesktopOrLaptop, 'text-[18px]': isTabletOrMobile})}>
                                <span className='font-bold'>FMS(Fleet Management System)</span>를 활용하여 운행중인 차량의 <br/>실시간
                                위치, 누적 경로, 배터리 연비, 급제동 등 다양한 운전정보와 관제 데이터를<br/> 실시간 확인할 수 있기 때문에 효율적 차량을 관리할 수
                                있습니다.<br/>
                                또한 안전 운행점수 측정으로 영업용 차량의 사고율 감소에 도움을 드립니다.
                            </div>
                        </div>
                        {
                            isDesktopOrLaptop &&
                            <div className='basis-1/3 pt-[20px] pl-8'>
                                <img src={Icon2} alt={'아이콘2'} width={200}/>
                            </div>
                        }
                    </div>


                    <div className='flex_center w-full'>
                        <div className={cn(' base_font', {
                            'text-[32px] basis-2/3': isDesktopOrLaptop,
                            'text-[22px] w-full': isTabletOrMobile
                        })}>
                            <div className='font-bold text-black flex flex-col'>
                                <p>
                                    배터리팩 건강관리
                                </p>
                            </div>
                            <div className={cn({'text-[22px]': isDesktopOrLaptop, 'text-[18px]': isTabletOrMobile})}>
                                <span className='font-bold'>배터리 관리 시스템 (BMS, Battery Management System)</span> 을
                                통한<br/> 데이터 활용으로 배터리 건강 상태를 진단하여 솔루션을 제공하고 있습니다. <br/>
                                이처럼 손쉬운 배터리 효율성 확인으로 향후 문제 발생을 예측하여<br/>고객님들의 안정적인 영업용 차량 운영을 지원합니다!
                            </div>
                        </div>
                        {
                            isDesktopOrLaptop &&
                            <div className='basis-1/3 pt-[20px] pl-8'>
                                <img src={Icon3} alt={'아이콘3'} width={200}/>
                            </div>
                        }
                    </div>

                    <div className='flex_center w-full'>
                        <div className={cn(' base_font', {
                            'text-[32px] basis-2/3': isDesktopOrLaptop,
                            'text-[22px] w-full': isTabletOrMobile
                        })}>
                            <div className='font-bold text-black flex flex-col'>
                                <p>
                                    재제조 배터리 할인구매{isTabletOrMobile && <br/>}
                                    <span className={cn({
                                        'text-[20px]': isDesktopOrLaptop,
                                        'text-[16px]': isTabletOrMobile
                                    })}>(서비스 3년 계약시)</span>
                                </p>

                            </div>
                            <div className={cn({'text-[22px]': isDesktopOrLaptop, 'text-[18px]': isTabletOrMobile})}>
                                비스를 3년 이상 이용해주신 고객님들에 한하여 장기간 차량운행시 <br/>
                                배터리 수명이 저하되었을때, 재제조 배터리의 할인 혜택을 제공해드립니다.<br/>
                                배터리 내외부 케이스와 성능까지 신품과 대등한 품질의 효율 제공으로<br/>
                                배터리 수명 저하로 인한 폐차와 신차 구입 비용 절감 효과도 함께 누리실수 있습니다.
                            </div>
                        </div>
                        {
                            isDesktopOrLaptop &&
                            <div className='basis-1/3 pt-[20px] pl-8'>
                                <img src={Icon4} alt={'아이콘4'} width={200}/>
                            </div>
                        }
                    </div>*/}
                </div>
            </div>
            <ProductWrap/>
            <div
                className='mx-auto my-0 bg-white w-[calc(100%-80px)] max-w-[1062px] border-b-[2px] border-black  pb-[80px]'
                ref={qaRef}>
            <div className='flex flex-col text-black'>
                <h2 className={cn('font-bold text-black', {
                        'text-[18px]': isTabletOrMobile,
                        'text-[24px]': isDesktopOrLaptop
                    })}>
                        자주 묻는 질문
                    </h2>
                    <QnaDiv/>
                </div>
            </div>
        </div>
    )
}
