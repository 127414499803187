import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import axiosInstance from "../api/axiosInstans";
import React, {SetStateAction} from "react";
import {CommonResponse, UserType} from "../@types/common";


export const Post = async <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
): Promise<AxiosResponse<CommonResponse<T>>> => {
    return await axiosInstance.post(url, data, config);
};

export function isScrollable() {
    // 현재 문서의 높이
    const documentHeight = document.documentElement.scrollHeight;
    // 창의 높이
    const windowHeight = window.innerHeight;

    // 현재 문서의 높이가 창의 높이보다 크면 스크롤이 생겼음을 의미
    return documentHeight > windowHeight;
}

interface RefPros {
    registRef: React.RefObject<HTMLDivElement>;
    infoRef: React.RefObject<HTMLDivElement>;
    limitRef: React.RefObject<HTMLDivElement>;
    qaRef: React.RefObject<HTMLDivElement>;
    setActiveSection : React.Dispatch<SetStateAction<string>>;
}
export function updateActiveSection({qaRef, infoRef, limitRef, registRef, setActiveSection} : RefPros){
    if (qaRef.current && window.scrollY >= qaRef.current.offsetTop) {
        setActiveSection('qa');
    } else if (infoRef.current && window.scrollY >= infoRef.current.offsetTop) {
        setActiveSection('info');
    } else if (limitRef.current && window.scrollY >= limitRef.current.offsetTop) {
        setActiveSection('limit');
    } else if (registRef.current && window.scrollY >= registRef.current.offsetTop) {
        setActiveSection('register');
    } else {
        setActiveSection('');
    }
}


export function modalStyles(type : string){
    const mobi_edit = {
        overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex : 20,
        },
        content: {
            backgroundColor : "rgb(238,238,238)",
            left: "50%",
            margin: "auto",
            width: "calc(100% - 20px)",
            height: "calc(100vh - 20vh)",
            padding: "0",
            transform : "translate(-50%,0)"
        },
    };
    const edit = {
        overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex : 20,
        },
        content: {
            backgroundColor : "#eeeeee",
            left: '0',
            margin: "auto",
            width: "460px",
            height : '880px',
            padding: "0",
            //transform : "translate(-50%,0)"
        },
    };
    const mobi_cnsl = {
        overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex : 20,
        },
        content: {
            backgroundColor : "rgb(238,238,238)",
            left: "50%",
            margin: "auto",
            width: "calc(100% - 20px)",
            height: "calc(100vh - 20vh)",
            padding: "0",
            transform : "translate(-50%,0)"
        },
    };

    const cnsl = {
        overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex : 20,
        },
        content: {
            backgroundColor : "#eeeeee",
            left: '0',
            margin: "auto",
            width: "460px",
            height : '750px',
            padding: "0",
            //transform : "translate(-50%,0)"
        },
    };
    let modalStyle = {
        mobi : {},
        web : {}
    };

    if(type === 'edit') {
        modalStyle.mobi = mobi_edit;
        modalStyle.web = edit;
    }
    if(type === 'cnsl'){
        modalStyle.mobi = mobi_cnsl;
        modalStyle.web = cnsl;
    }

    return modalStyle;
}



// PDF 파일 다운로드 함수
export function downloadPdf(pdfUrl : string){
    // PDF 파일의 URL
    // axios를 사용하여 PDF 파일 다운로드
    axios.get(pdfUrl, { responseType: 'blob' })
        .then(response => {
            // 다운로드할 파일의 blob 생성
            const blob = new Blob([response.data]);

            // blob을 URL로 변환
            const url = window.URL.createObjectURL(blob);

            // a 태그를 생성하여 다운로드
            const a = document.createElement('a');
            a.href = url;
            document.body.appendChild(a);
            a.click();

            // 다운로드 후 URL 해제
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error('PDF 다운로드 중 오류 발생:', error);
        });
}
export async function googleSheet(data : any ){
    try {
        const scriptURL = 'https://script.google.com/macros/s/AKfycbxtUTZkD1pThqWcpixQuXOYKoo0DRv8tVb7qHRB2anFcBavYPA0PDavbTCnO5AFXKfJ/exec';
        let res = await axios.post(scriptURL, data);
        if(res.status === 200){
            return 200;
        }
    }catch(e){
        console.log(e);
    }
}

export async function sendCode(cell : string, code : string){
    try{

        let param = {
            gubun : 'S',
            msg : `본인 인증 번호는 [${code}]입니다. `,
            cell : cell,
            upk : 6
        }
        await axiosInstance.post('sendMsg', param).then((r) => {
            alert('인증번호가 전송되었습니다.');
        });

    }catch(e){
        alert('서비스 오류입니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
    }
}



export const getData = async (): Promise<UserType | null> => {
    try {
        const response =  await axiosInstance.post<UserType>('userInfo');
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to get userInfo');
    }
};


interface UserInfo {
    cName : string;
    cCell : string;
}
export async function getUserInfo(keyCode: string): Promise<UserInfo | null> {
    try {
        const response = await axiosInstance.post<UserInfo>('getUser', { keyCode: keyCode });
        return response.data; // 서버 응답에서 데이터를 반환합니다.
    } catch (e) {
        alert('서비스 오류입니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
        return null; // 에러 발생 시 null을 반환하여 함수의 반환 타입을 일관성 있게 유지합니다.
    }
}


export function getErrorMsg(key : any) {
    console.log(key);
    if(key === 'cName'){
        return '이름을 입력해주세요.';
    }else if(key === 'cJumin'){
        return '주민등록 번호를 입력해주세요.';
    }else if(key === 'cCell'){
        return '휴대폰 번호를 입력해주세요.';
    }else if(key === 'businessType'){
        return '사업자를 선택해주세요.';
    }else if(key === 'carUse'){
        return '차량 용도를 선택해주세요.';
    }else if(key === 'carType'){
        return '차종을 선택해주세요.';
    }else if(key === 'carNum') {
        return '차량번호를 입력해주세요.';
    }else if(key === 'obuYn'){
        return 'OBU(단말기) 설치 여부를 선택해주세요.';
    }else if(key === 'payType'){
        return '결제 수단을 선택해주세요.';
    }else if(key === 'payNum'){
        return '계좌번호/카드번호를 입력해주세요.';
    }else if(key === 'payExpir'){
        return '은행명/카드유효기간을 입력해주세요.';
    }else if(key === 'payDate'){
        return '결제일을 선택해주세요.';
    }else if(key === 'time'){
        return '상담시간을 선택해주세요.';
    }else if(key === 'collectionYn' || key === 'provisionYn' || key === 'inquiryYn' || key === 'sharingYn'){
        return '정보 제공 동의는 필수입니다.';
    }
}
export function customJumin(e : React.ChangeEvent<HTMLInputElement>, onChange : any) {
        const trimValue = e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{0,6})(\d{0,7})$/g, '$1-$2').replace(/-{1,2}$/g, '');
        const copyInput = document.createElement("input");
        copyInput.value = trimValue;

        onChange(copyInput)
    }

export async function sendMsg (cell : string,  code : string)  {
    try {
        await sendCode(cell, code).then((res : any ) => {
            return code;
        });
    }catch (e){
        alert('서비스 오류입니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
    }
}



export function makeCode(){
    let temp = '';
    for(let i = 0; i<5; i++){
        temp += (Math.floor(Math.random() * 9) + 1).toString();
    }
    return temp;
}


export function updateSet(set : any, id: any, item : string, setValue : any) {
        const updatedSet = new Set(set);
        console.log(updatedSet);
        if (updatedSet.has(id)) {
            updatedSet.delete(id);
            // @ts-ignore
            setValue(id,'Y');
        } else {
            updatedSet.add(id);
            // @ts-ignore
            setValue(id,'N');
        }
        return updatedSet;
}


export function getClock() {
    return [
        {'hour': '09:00'},
        {'hour': '09:30'},
        {'hour': '10:00'},
        {'hour': '10:30'},
        {'hour': '11:00'},
        {'hour': '13:00'},
        {'hour': '13:30'},
        {'hour': '14:00'},
        {'hour': '14:30'},
        {'hour': '15:00'},
        {'hour': '15:30'},
        {'hour': '16:00'},
        {'hour': '16:30'},
        {'hour': '17:00'},
        {'hour': '17:30'},
    ];
}


export function getMsg(name : string, code : string){
    return [
        {
            'index' : 0,
            'title' : '가입안내',
            'content' : '안녕하세요, 피트인 입니다.\n' +
                `${name}님! 요청하신 배터리 서비스 가입신청 링크 전달 드립니다.\n` +
                '\n' +
                '- 가입신청 링크:\n' +
                `https://www.pitin-ev.simg.kr/register?key=${code}` +
                '\n' +
                '감사합니다.\n' +
                '\n' +
                '※ 피트인 상담문의\n' +
                '- 전화: 1670-0470\n' +
                '- 상담시간: 오전 9시~오후 6시\n' +
                '(점심시간: 오전 11시 30분~오후 1시/공휴일 및 주말 제외)\n' +
                '\n' +
                '※ 피트인 홈페이지:\n' +
                'https://pitin-ev.simg.kr'
        }
    ]
}

export default function userDataSet(){
    return  [
        {
            key : 1,
            data : 'cName',
            title : '이름'
        },
        {
            key : 2,
            title : '생년월일',
            data : 'birth'
        },
        {
            key : 3,
            title : '핸드폰번호',
            data : 'cCell'
        },
        {
            key : 4,
            title : '차량번호',
            data : 'carNum'
        },
        {
            key : 5,
            title : '사업자',
            data : 'bussinessType'
        },
        {
            key : 6,
            title : '차량용도',
            data : 'carUse'
        },
        {
            key : 7,
            title : '차종',
            data : 'carType'
        },
        {
            key : 8,
            title : '납부요금(월)',
            data : 'premiums'
        },
        {
            key : 9,
            title : '결제방법',
            data : 'payType'
        },
        {
            key : 10,
            title : '결제일',
            data : 'cpayDt'
        },
        {
            key : 11,
            title : '납부회차',
            data : 'payPeriod'
        },
        {
            key : 12,
            title : '미납여부',
            data : 'NonPaymentYn'
        },
        {
            key : 13,
            title : 'FMS 아이디/비밀번호',
            data : 'fmsInfo'
        }
    ]
}
