import React, { useEffect, useRef, useState} from "react";
import axiosInstance from "../../api/axiosInstans";
import {sendCode} from "../../utils/common";
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import { userInfoActions} from "../../redux/slice/authSlice";
import {useNavigate} from "react-router-dom";


export default function CertiComp(){
    const [code, setCode] = useState('');
    const [userCode, setUserCode] = useState('');
    const [cell, setCell] = useState('');
    const [carNum, setCarNum] = useState('');
    const [sendFlag, setSendFlag] = useState(false);
    const [step, setStep] = useState('step1');
    const codeRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();
    const isMobi = /Mobi/i.test(window.navigator.userAgent);
    const dispatch = useDispatch();
    // @ts-ignore
    const userInfo = useSelector((state) => state.user);
    const sendMsg = async (e : React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            let temp = '';
            for(let i = 0; i<5; i++){
                temp += (Math.floor(Math.random() * 9) + 1).toString();
            }
            setCode(temp);
            console.log(temp);
            await sendCode(userInfo[0].cCell, temp).then((res : any ) => {
                setSendFlag(true);
            });
            console.log('setting', code)
        }catch(e){
            alert('서비스 오류입니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
        }
    }

    const confirmCode = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            e.preventDefault();
            if(step === 'step1'){
                let userInfo : any = await axiosInstance.post('/existCarNum', {carNum : carNum})
                if(carNum === ''){
                    alert('차량번호를 입력 해주세요.');
                    return;
                }
                if(userInfo.code === '400'){
                    alert('등록된 차량번호가 없습니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
                }else {
                    setStep('step2');
                    setCell(userInfo.cCell);
                    dispatch(userInfoActions.setUserInfo({
                        cName: userInfo.cName,
                        cCell: userInfo.cCell,
                        cJumin: userInfo.cJumin,
                        carNum: userInfo.carNum,
                        carType: userInfo.carType,
                        carUse: userInfo.carUse,
                        bussinessType: userInfo.bussinessType
                    }))
                }
            }
            if(step === 'step2') {
                if(code === userCode){
                    let result : any = await axiosInstance.post('/login', {cCell : userInfo[0].cCell});
                    if(!result){
                        alert('등록된 차량번호가 없습니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
                    }else {
                        navigate('/main/search');
                    }
                }else {
                    alert('인증코드가 틀립니다. \n확인하고 다시 시도해주세요.');
                }
            }
        }catch (e){
            alert('서비스 오류입니다. \n본사에 문의주시기 바랍니다. \n☎ 1670-0470');
        }

    }

    useEffect(() => {
        if(codeRef.current !== null){
            codeRef.current!.focus();
        }
        setTimeout(function(){
            setSendFlag(false);
        }, 10000);
    }, [sendFlag]);

    return(
        <div className={cn('flex_center flex-col  rounded-lg', {
            'w-[calc(100%-20px)]': isMobi,
            'py-16 w-[650px] border': !isMobi
        })}>
            <div className='flex_center w-full'>
                {
                    step === 'step1' ?
                        <div className='flex flex-col space-y-4 my-8'>
                            <div className='flex_center space-x-6 w-[450px]'>
                                <label>
                                    차량번호 :
                                </label>
                                <input type={'text'} className='input'
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCarNum(e.currentTarget.value)}/>
                            </div>
                        </div>
                        :
                        <div className='flex flex-col space-y-4'>
                            <div className='flex_center space-x-6 w-[450px]'>
                                <label>
                                    전화번호 :
                                </label>
                                <input type={'text'} className={cn('input', {'bg-[#eeeeee]': step !== 'step1'})}
                                       disabled={step !== 'step1' && true}
                                       defaultValue={cell || ''}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCell(e.currentTarget.value)}/>
                            </div>
                            <div className='flex_center space-x-6 pb-16 w-[450px]'>
                                <label>
                                    인증번호 :
                                </label>
                                <input ref={codeRef} type={'text'}
                                       className={cn('input', {'bg-[#eeeeee]': code === ''})}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserCode(e.currentTarget.value)}
                                       disabled={code === '' && true}/>
                            </div>
                        </div>

                }
                {
                    !isMobi && step !== 'step1' && <button
                        className={cn('w-[100px] h-[40px] border rounded-2xl bg_base text-white', {'bg-[#78ACDA]': sendFlag})}
                        disabled={sendFlag && true} onClick={sendMsg}>전송</button>
                }
            </div>
            <div className='flex space-x-6'>
                {
                    isMobi && step !== 'step1' &&
                    <button
                        className={cn('w-[100px] h-[40px] border rounded-2xl bg_base text-white', {'bg-[#78ACDA]': sendFlag})}
                        disabled={sendFlag && true} onClick={sendMsg}>전송
                    </button>
                }
                <button className={cn('w-[100px] h-[40px] border rounded-2xl bg_base text-white',{'bg-[#78ACDA]': code === '' && step === 'step2'})} onClick={confirmCode} disabled={code === '' && step === 'step2' }>
                    확인
                </button>
            </div>
        </div>

    )
}
