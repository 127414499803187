import {useMediaQuery} from "react-responsive";
import cn from 'classnames';
export default function QnaDiv(){
    const isMobile = useMediaQuery({maxWidth : 930})
    return (
        <div className='w-full mt-8'>
            <div>
                <div className='border px-8 py-2 cursor-pointer hover:font-bold'>
                    <p>Q1. 가입 방법</p>
                </div>
                <div>
                    <div className={cn('border-l border-b border-r px-8 py-2 base_font', {'text-[12px]': isMobile})}>
                        홈페이지 상단에 <span className='font-bold'>상담신청 버튼</span>을 눌러 상담일자를 지정해주시면, 지정일자 상담원이 전화 안내를 드려 가입을 도와드리고 있습니다.
                    </div>
                </div>
            </div>
            <div>
                <div className='border-l border-b border-r px-8 py-2 cursor-pointer hover:font-bold'>
                    <p>Q2. 가입시 필요서류</p>
                </div>
                <div>
                    <div className={cn('border-l border-b border-r px-8 py-2 base_font', {'text-[12px]': isMobile})}>
                        • 개인 : 차량번호 및 서비스 대상의 전기차량<br/><br/>
                        • 법인 : 사업자등록증 및 법인인감증명
                    </div>
                </div>
            </div>
            <div>
                <div className='border-l border-b border-r px-8 py-2 cursor-pointer hover:font-bold'>
                    <p>Q3. 보장 범위</p>
                </div>
                <div>
                    <div className={cn('border-l border-b border-r px-8 py-2 base_font', {'text-[12px]': isMobile})}>
                        사고 및 자기과실로 인한 배터리 파손 모두 보장 범위에 해당하나, 법적위반 행위 및 차량 개조와 경주 목적으로 인한 운행 사고는 보장되지 않습니다.
                    </div>
                </div>
            </div>
            {/*<div>
                <div className='border-l border-b border-r px-8 py-2 cursor-pointer hover:font-bold'>
                    <p>Q4. 보장 범위</p>
                </div>
                <div>
                    <div className={cn('border-l border-b border-r px-8 py-2 base_font',{'text-[12px]' : isMobile})}>
                        타 차량과의 충돌, 추돌, 접촉으로 인한 배터리 파손은 모두 교체가 가능합니다.
                        침수, 화재, 폭발 등 차량 외부 요인으로 인한 배터리 고장도 보상 대상입니다.
                        다만, 운전자 과실(음주운전 등)로 인한 사고는 보상에서 제외되니 주의하시기 바랍니다.
                        또한 차량의 개조, 경주, 영업 목적 운행 등으로 인한 배터리 파손도 보장되지 않습니다.
                    </div>
                </div>
            </div>*/}
        </div>
    )
}
