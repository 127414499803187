import React from "react"
import { Navigate } from "react-router-dom"

// 페이지 경로
import Login from "../pages/login"
import Home from "../pages/home";
import Main from "../pages/main";
import Register from "../pages/register";



interface RouteProps {
    path: string
    component: any
    exact?: boolean
}

// 유저 페이지 경로
const userRoutes: Array<RouteProps> = [
    { path: "/", exact: true, component: <Navigate to="/" /> },
    { path: "/main/:category", exact: true, component: <Main/> },
]

const authRoutes: Array<RouteProps> = [
    //Authentication pages
    { path: "/", component: <Home /> },
    { path: "/register", component: <Register /> },
    { path: "/login", component: <Login /> }
]

export { userRoutes, authRoutes }
