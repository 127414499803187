

export const bankList = () =>{
    return [
        '한국씨티은행',
        '하나은행',
        '토스뱅크',
        '케이뱅크',
        '카카오뱅크',
        '중국은행',
        '중국공상은행',
        '제주은행',
        '전북은행',
        '저축은행',
        '우체국',
        '우리은행',
        '신협중앙회',
        '신한은행',
        '수협은행',
        '새마을금고',
        '산업은행',
        '산림조합중앙회',
        '부산은행',
        '뱅크오브아메리카',
        '도이치은행',
        '농협은행',
        '기업은행',
        '국민은행',
        '광주은행',
        '경남은행',
        'SC제일은행',
        'JP모간체이스은행',
        'IM뱅크(구대구은행)',
        'HSBC은행',
        'BNP파리바은행',
        '중국건설은행',
    ];
}

export const carList = (type : any) => {
    const type1 = ['니로(DE) EV','EV6','아이오닉 5 EV (롱레인지)'];
    const type2 = ['봉고 EV','포터 EV'];

    switch (type) {
        case '01' :
            return type1;
        case '02' :
            return type2;
    }
}
