import React, {SetStateAction, useEffect, useState} from "react";
import Modal from "react-modal";
import {modalStyles} from "../../utils/common";
import Close from '../../assets/images/btn_close_24px.svg';
import cn from 'classnames';
import axiosInstance from "../../api/axiosInstans";
import Consulting from "./consulting";

interface Props {
    isOpen : boolean;
    setIsOpen : React.Dispatch<SetStateAction<boolean>>;
    type : string;
}

export default function Modals({isOpen, setIsOpen, type} : Props){
    const isMobi = /Mobi/i.test(window.navigator.userAgent);
    const [step, setStep] = useState('step1');
    const styles = modalStyles(type);
    useEffect( () => {
        axiosInstance.post('/count',{job : 'popup'}).then(r => console.log('today count up'));
    }, []);

    useEffect(() => {

    }, [step]);
    return (
        <Modal isOpen={isOpen} style={isMobi ? styles.mobi : styles.web}>
            <div className='h-full w-full'>
                <div
                    className={cn('flex_center h-[60px] border-b w-full bg_base', {'fixed top-0 z-10': isMobi})}>
                    <p className='pl-4 text-[16px] font-[600] text-white'>상담 신청</p>
                    <button className='ml-auto pr-4' onClick={(e: React.MouseEvent) => setIsOpen(!isOpen)}>
                        <img src={Close} alt={'close'}/>
                    </button>
                </div>
                <Consulting setIsOpen={setIsOpen}/>
            </div>
            {/*{
                step === 'step1' ?
                    <div className='h-full w-full'>
                        <div
                            className={cn('flex_center h-[60px] border-b w-full bg_base', {'fixed top-0 z-10': isMobi})}>
                            <p className='pl-4 text-[16px] font-[600] text-white'>상담 신청</p>
                            <button className='ml-auto pr-4' onClick={(e: React.MouseEvent) => setIsOpen(!isOpen)}>
                                <img src={Close} alt={'close'}/>
                            </button>
                        </div>
                        <RegisterForm  setStep={setStep} setCmpk={setCmpk}/>
                    </div>
                    :
                    <div className='h-full w-full'>
                        <div
                            className={cn('flex_center h-[60px] border-b w-full bg_base', {'fixed top-0 z-10': isMobi})}>
                            <p className='pl-4 text-[16px] font-[600] text-white'>서명</p>
                            <button className='ml-auto pr-4' onClick={(e: React.MouseEvent) => setIsOpen(!isOpen)}>
                                <img src={Close} alt={'close'}/>
                            </button>
                        </div>
                        <Sign cmpk={cmpk} setIsOpen={setIsOpen}/>
                    </div>
            }*/}
        </Modal>
    )
}
