import LoadingImg from '../assets/images/Loading.gif';
import {useSelector} from "react-redux";
import cn from 'classnames';
const Loading = () => {
    const loading = useSelector((state : any) => state.loading.isLoading);
    const isMobi = /Mobi/i.test(window.navigator.userAgent);
    return (
        <>
            {
                loading === false ? "" :
                    <>
                        <div className='fixed inset-0 z-50 bg-black/50'>
                            <div className={isMobi ? 'mobi_loading' : 'web_loading'}>
                                <img src={LoadingImg} alt={'loading'} width={isMobi ? '100' : ''}/>
                            </div>
                        </div>
                    </>
            }
        </>

    )
}
export default Loading;
