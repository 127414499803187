import axios, { InternalAxiosRequestConfig } from 'axios';

// Axios 인스턴스 생성
const axiosInstance = axios.create({
    baseURL: 'https://pitin-ev.simg.kr/api/v1/prod',
    timeout: 5000,
    //withCredentials : true
});
// 요청 인터셉터
axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        let token = localStorage.getItem('token');
        if(token) config.headers['X-ACCESS-TOKEN'] = token;
        // 요청 보내기 전에 수행할 로직
        // config에 대한 수정이 필요하면 여기서 수정
        config.headers['X-API-SECRET'] = '40078030-F15D-11EE-8CAD-550F9CAFDA95';
        return config;
    },
    (error) => {
        // 요청 에러 시 수행할 로직
        return Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
    (response) => {
        let token = response.data.accessToken;
        if(token){
            localStorage.setItem('token', response.data.accessToken);
        }
        return response.data;
    },
    async (error) => {
        const {response} = error;
        if (response.status === 401) {
            alert('세션 유효기간 만료');
            localStorage.clear();
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
